<!-- 选择头像框 -->
<template>
  <div class="select-main" @touchmove.prevent>
    <div class="pc_chooseitem">
      <div class="order-title order-title-1">请选择您的定制款式</div>
      <div class="item-content">
        <div v-for="(item, index) in mobObj" :key="index" @click="setSelectIndex(index)">
          <frameItem size="small" :frameData="item" :select="selectIndex==index+1"></frameItem>
        </div>
      </div>
      <div class="order-title order-title-2">请选择您的定制信息</div>
      <input
        type="text"
        class="infoInput"
        placeholder="点击填写内容"
        maxlength="3"
        v-model="frameInfo"
        @keyup="frameInfo=frameInfo.replace(/\s+/g,'')"
      />
      <div class="confirm-btn" @click="clickConfirmBtn">确定定制内容</div>
    </div>
    <mobConfirmDialog v-if="showMOBConfirmDia" :selectData="this.selectData"></mobConfirmDialog>
  </div>
</template>

<script>
export default {
  props: ["mobObj"],
  data() {
    return {
      selectIndex: 0,
      frameInfo: "", //输入的定制信息
      showMOBConfirmDia: false,
      selectData:null
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    // console.log("this.mobObj=>", this.mobObj);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  components: {
    frameItem: () => import("./frameItem"),
    mobConfirmDialog: () => import("./mobConfirmDialog")
  },
  methods: {
    setSelectIndex: function(val) {
      this.selectIndex = Number(val) + 1;
    },
    clickConfirmBtn: function() {
      // console.log("点击了确定按钮");
      // console.log("定制内容=>", this.frameInfo);
      if (this.selectIndex == 0) {
        alert("请选择定制款式");
        return;
      }
      //二次防空格
      this.frameInfo = this.frameInfo.replace(/\s+/g, "");
      if (!this.frameInfo) {
        alert("请输入定制内容");
        return;
      }
      this.selectData = {
        ...this.mobObj[this.selectIndex - 1],
        selectIndex: this.selectIndex,
        content: this.frameInfo
      };
      this.showMOBConfirmDia = true;
    }
  }
};
</script>
<style scoped lang='scss'>
@import "../../style/main.scss";
.select-main {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pc_chooseitem {
  width: 90vw;
  height: 131.6vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include imgbg(imgUrl("mob_chooseitem.png"));
}
.order-title {
  width: 100%;
  position: absolute;
  text-align: center;
  color: white;
  font-size: 3.6vw;
  line-height: 3.6vw;
}
.order-title-1 {
  margin-top: 10.6vw;
}
.order-title-2 {
  margin-top: 1.9vw;
}
.item-content {
  width: 80.2vw;
  height: 32.7vw;
  margin-left: 4.9vw;
  margin-top: 16.1vw;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.infoInput {
  width: 78.9vw;
  height: 12.1vw;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  text-align: center;
  margin-left: 5.5vw;
  margin-top: 7.8vw;
  border-radius: 2.4vw;
  color: white;
  font-size: 3.2vw;
  -webkit-user-select: text !important;
}
::-webkit-input-placeholder {
  color: #ffbc55;
  font-size: 3.2vw;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ffbc55;
  font-size: 3.2vw;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffbc55;
  font-size: 3.2vw;
}
:-ms-input-placeholder {
  color: #ffbc55;
  font-size: 3.2vw;
}
input:focus {
  outline: none;
}
input::placeholder {
  color: #ffbc55 !important;
}
.confirm-btn {
  width: 78.9vw;
  height: 12.1vw;
  background-color: #ffbc55;
  border-radius: 2.4vw;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  color: #825414;
  font-size: 3.9vw;
  margin-left: 5.5vw;
  margin-top: 46.4vw;
}
</style>